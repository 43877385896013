<!-- SLIDER START -->
<div class="rev_slider_wrapper fullscreen-container" data-alias="goodnews-header" data-source="gallery"
     id="welcome_wrapper" style="background:#eeeeee;padding:0;">
  <div class="rev_slider fullscreenbanner" data-version="5.4.3.1" id="welcome" style="display:none;">
    <ul>
      <!-- SLIDE 1 -->
      <li data-description=""
          data-easein="default"
          data-easeout="default"
          data-fsmasterspeed="300"
          data-fsslotamount="7"
          data-fstransition="fade"
          data-hideafterloop="0"
          data-hideslideonmobile="off"
          data-index="rs-901"
          data-masterspeed="default"
          data-param1=""
          data-param10=""
          data-param2=""
          data-param3=""
          data-param4=""
          data-param5=""
          data-param6=""
          data-param7=""
          data-param8=""
          data-param9=""
          data-rotate="0"
          data-saveperformance="off"
          data-slotamount="default"
          data-thumb="assets/images/main-slider/slider1/slide1.jpg"
          data-title="Click"
          data-transition="fadethroughdark">
        <!-- MAIN IMAGE -->
        <img alt="" class="rev-slidebg"
             data-bgfit="cover" data-bgparallax="4"
             data-bgposition="center center" data-lazyload="assets/images/main-slider/slider1/slide1.jpg" data-no-retina src="assets/images/main-slider/slider1/slide1.jpg">
        <!-- LAYERS -->
        <!-- LAYER NR. 0 [ for overlay ] -->
        <div class="tp-caption tp-shape tp-shapewrapper "
             data-basealign="slide"
             data-frames='[
                            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                            ]' data-height="full"
             data-hoffset="['0','0','0','0']" data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive="off"
             data-responsive_offset="off"
             data-textAlign="['left','left','left','left']"
             data-type="shape"
             data-voffset="['0','0','0','0']"
             data-whitespace="nowrap"
             data-width="full"
             data-x="['center','center','center','center']"
             data-y="['middle','middle','middle','middle']"
             id="slide-901-layer-0"

             style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0;">
        </div>

        <!-- LAYER NR. 1 [ Black Box ] -->
        <div class="tp-caption   tp-resizeme"
             data-fontsize="['46','46','46','32']"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-height="['','','','']"
             data-hoffset="['30','30','30','30']" data-lineheight="['56','56','56','50']"
             data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive_offset="on"

             data-textAlign="['left','left','left','left']"
             data-type="text"
             data-voffset="['250','250','250','250']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['0','0','0','0']"
             data-x="['left','left','left','left']"
             data-y="['top','top','top','top']"
             id="slide-901-layer-1"

             style="z-index: 13;
                            white-space: normal;
                            font-weight: 900;
                            color:#fff;
                            border-width:0;">
          <div class=" rev-slider-style-1"></div>
        </div>


        <!-- LAYER NR. 2 [ for title ] -->
        <div class="tp-caption   tp-resizeme"
             data-fontsize="['56','56','38','28']"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-height="['none','none','none','none']"
             data-hoffset="['40','40','40','40']" data-lineheight="['66','66','48','38']"
             data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[20,20,20,20]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[5,5,5,5]"
             data-responsive_offset="on"

             data-textAlign="['left','left','left','left']"
             data-type="text"
             data-voffset="['270','270','270','270']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['700','700','96%','96%']"
             data-x="['left','left','left','left']"
             data-y="['top','top','top','top']"
             id="slide-901-layer-2"

             style="z-index: 13;
                            white-space: normal;
                            font-weight: 900;
                            color:#fff;
                            border-width:0;">
          <div style="font-family: 'DM Serif Text', serif;text-transform:uppercase;">
            <span class="text-primary"> Discover a</span><br>
            hotel that defines a new dimension of luxury
          </div>

        </div>

        <!-- LAYER NR. 3 -->
        <div class="tp-caption tp-resizeme"
             data-fontsize="['400','400','400','80']"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-height="['none','none','none','none']"
             data-hoffset="['40','40','40','40']" data-lineheight="['66','66','48','38']"
             data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive_offset="on"

             data-textAlign="['right','right','right','left']"
             data-type="text"
             data-voffset="['400','570','570','180']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['800','800','800','800']"
             data-x="['right','right','right','left']"
             data-y="['top','top','top','top']"
             id="slide-901-layer-3"

             style="z-index: 13;
                            white-space: normal;
                            font-weight: 900;
                            color:rgba(0,0,0,0);">
              <span class="slider-text-outline"
                    style="font-family: 'Roboto', sans-serif;text-transform:uppercase;">01</span>
        </div>

        <!-- LAYER NR. 4 [ for see all service botton ] -->
        <div class="tp-caption tp-resizeme"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]'
             data-height="['none','none','none','none']" data-hoffset="['40','40','40','40']"

             data-lineheight="['none','none','none','none']" data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive_offset="on"

             data-textAlign="['left','left','left','left']"
             data-type="text"
             data-voffset="['570','570','520','460']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['300','300','300','300']"
             data-x="['left','left','left','left']"
             data-y="['top','top','top','top']"
             id="slide-901-layer-4"

             style="z-index:13; text-transform:uppercase;">
<!--          <a class="site-button slider-btn-left btn-half" href="javascript:"><span> More About</span><em></em></a>-->
        </div>


      </li>


      <!-- SLIDE 2 -->
      <li data-description=""
          data-easein="default"
          data-easeout="default"
          data-fsmasterspeed="300"
          data-fsslotamount="7"
          data-fstransition="fade"
          data-hideafterloop="0"
          data-hideslideonmobile="off"
          data-index="rs-902"
          data-masterspeed="default"
          data-param1=""
          data-param10=""
          data-param2=""
          data-param3=""
          data-param4=""
          data-param5=""
          data-param6=""
          data-param7=""
          data-param8=""
          data-param9=""
          data-rotate="0"
          data-saveperformance="off"
          data-slotamount="default"
          data-thumb="assets/images/main-slider/slider1/slide2.jpg"
          data-title="Click"
          data-transition="fadethroughdark">
        <!-- MAIN IMAGE -->
        <img alt="" class="rev-slidebg"
             data-bgfit="cover" data-bgparallax="4"
             data-bgposition="center center" data-lazyload="assets/images/main-slider/slider1/slide2.jpg" data-no-retina src="assets/images/main-slider/slider1/slide2.jpg">
        <!-- LAYERS -->
        <!-- LAYER NR. 0 [ for overlay ] -->
        <div class="tp-caption tp-shape tp-shapewrapper "
             data-basealign="slide"
             data-frames='[
                            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                            ]' data-height="full"
             data-hoffset="['0','0','0','0']" data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive="off"
             data-responsive_offset="off"
             data-textAlign="['left','left','left','left']"
             data-type="shape"
             data-voffset="['0','0','0','0']"
             data-whitespace="nowrap"
             data-width="full"
             data-x="['center','center','center','center']"
             data-y="['middle','middle','middle','middle']"
             id="slide-902-layer-0"

             style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0;">
        </div>

        <!-- LAYER NR. 1 [ Black Box ] -->
        <div class="tp-caption   tp-resizeme"
             data-fontsize="['46','46','46','32']"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-height="['','','','']"
             data-hoffset="['30','30','30','30']" data-lineheight="['56','56','56','50']"
             data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive_offset="on"

             data-textAlign="['left','left','left','left']"
             data-type="text"
             data-voffset="['250','250','250','250']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['0','0','0','0']"
             data-x="['left','left','left','left']"
             data-y="['top','top','top','top']"
             id="slide-902-layer-1"

             style="z-index: 13;
                            white-space: normal;
                            font-weight: 900;
                            color:#fff;
                            border-width:0;">
          <div class=" rev-slider-style-1"></div>
        </div>


        <!-- LAYER NR. 2 [ for title ] -->
        <div class="tp-caption   tp-resizeme"
             data-fontsize="['56','56','38','28']"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-height="['none','none','none','none']"
             data-hoffset="['40','40','40','40']" data-lineheight="['66','66','48','38']"
             data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[20,20,20,20]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[5,5,5,5]"
             data-responsive_offset="on"

             data-textAlign="['left','left','left','left']"
             data-type="text"
             data-voffset="['270','270','270','270']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['700','700','96%','96%']"
             data-x="['left','left','left','left']"
             data-y="['top','top','top','top']"
             id="slide-902-layer-2"

             style="z-index: 13;
                            white-space: normal;
                            font-weight: 900;
                            color:#fff;
                            border-width:0;">
          <div style="font-family: 'DM Serif Text', serif;text-transform:uppercase;">
            <span class="text-primary">Hilton</span><br>
            Garden Inn. Everything. Right where you need it.
          </div>

        </div>

        <!-- LAYER NR. 3 -->
        <div class="tp-caption tp-resizeme"
             data-fontsize="['400','400','400','80']"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-height="['none','none','none','none']"
             data-hoffset="['40','40','40','40']" data-lineheight="['66','66','48','38']"
             data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive_offset="on"

             data-textAlign="['right','right','right','left']"
             data-type="text"
             data-voffset="['400','570','570','180']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['800','800','800','800']"
             data-x="['right','right','right','left']"
             data-y="['top','top','top','top']"
             id="slide-902-layer-3"

             style="z-index: 13;
                            white-space: normal;
                            font-weight: 900;
                            color:rgba(0,0,0,0);">
              <span class="slider-text-outline"
                    style="font-family: 'Roboto', sans-serif;text-transform:uppercase;">02</span>
        </div>

        <!-- LAYER NR. 4 [ for see all service botton ] -->
        <div class="tp-caption tp-resizeme"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]'
             data-height="['none','none','none','none']" data-hoffset="['40','40','40','40']"

             data-lineheight="['none','none','none','none']" data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive_offset="on"

             data-textAlign="['left','left','left','left']"
             data-type="text"
             data-voffset="['570','570','520','460']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['300','300','300','300']"
             data-x="['left','left','left','left']"
             data-y="['top','top','top','top']"
             id="slide-902-layer-4"

             style="z-index:13; text-transform:uppercase;">
<!--          <a class="site-button slider-btn-left btn-half" href="javascript:"><span> More About</span><em></em></a>-->
        </div>

      </li>


      <!-- SLIDE 3 -->
      <li data-description=""
          data-easein="default"
          data-easeout="default"
          data-fsmasterspeed="300"
          data-fsslotamount="7"
          data-fstransition="fade"
          data-hideafterloop="0"
          data-hideslideonmobile="off"
          data-index="rs-903"
          data-masterspeed="default"
          data-param1=""
          data-param10=""
          data-param2=""
          data-param3=""
          data-param4=""
          data-param5=""
          data-param6=""
          data-param7=""
          data-param8=""
          data-param9=""
          data-rotate="0"
          data-saveperformance="off"
          data-slotamount="default"
          data-thumb="assets/images/main-slider/slider1/slide3.jpg"
          data-title="Click"
          data-transition="fadethroughdark">
        <!-- MAIN IMAGE -->
        <img alt="" class="rev-slidebg"
             data-bgfit="cover" data-bgparallax="4"
             data-bgposition="center center" data-lazyload="assets/images/main-slider/slider1/slide3.jpg" data-no-retina src="assets/images/main-slider/slider1/slide3.jpg">
        <!-- LAYERS -->
        <!-- LAYER NR. 0 [ for overlay ] -->
        <div class="tp-caption tp-shape tp-shapewrapper "
             data-basealign="slide"
             data-frames='[
                            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                            ]' data-height="full"
             data-hoffset="['0','0','0','0']" data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive="off"
             data-responsive_offset="off"
             data-textAlign="['left','left','left','left']"
             data-type="shape"
             data-voffset="['0','0','0','0']"
             data-whitespace="nowrap"
             data-width="full"
             data-x="['center','center','center','center']"
             data-y="['middle','middle','middle','middle']"
             id="slide-903-layer-0"

             style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0;">
        </div>

        <!-- LAYER NR. 1 [ Black Box ] -->
        <div class="tp-caption   tp-resizeme"
             data-fontsize="['46','46','46','32']"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-height="['','','','']"
             data-hoffset="['30','30','30','30']" data-lineheight="['56','56','56','50']"
             data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive_offset="on"

             data-textAlign="['left','left','left','left']"
             data-type="text"
             data-voffset="['250','250','250','250']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['0','0','0','0']"
             data-x="['left','left','left','left']"
             data-y="['top','top','top','top']"
             id="slide-903-layer-1"

             style="z-index: 13;
                            white-space: normal;
                            font-weight: 900;
                            color:#fff;
                            border-width:0;">
          <div class=" rev-slider-style-1"></div>
        </div>


        <!-- LAYER NR. 2 [ for title ] -->
        <div class="tp-caption   tp-resizeme"
             data-fontsize="['56','56','38','28']"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-height="['none','none','none','none']"
             data-hoffset="['40','40','40','40']" data-lineheight="['66','66','48','38']"
             data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[20,20,20,20]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[5,5,5,5]"
             data-responsive_offset="on"

             data-textAlign="['left','left','left','left']"
             data-type="text"
             data-voffset="['270','270','270','270']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['700','700','96%','96%']"
             data-x="['left','left','left','left']"
             data-y="['top','top','top','top']"
             id="slide-903-layer-2"

             style="z-index: 13;
                            white-space: normal;
                            font-weight: 900;
                            color:#fff;
                            border-width:0;">
          <div style="font-family: 'DM Serif Text', serif;text-transform:uppercase;">
            <span class="text-primary"> Whitelaw </span><br>
            Hotel. Clean Sheets, Hot Water, Stiff Drinks.
          </div>

        </div>

        <!-- LAYER NR. 3 -->
        <div class="tp-caption tp-resizeme"
             data-fontsize="['400','400','400','80']"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-height="['none','none','none','none']"
             data-hoffset="['40','40','40','40']" data-lineheight="['66','66','48','38']"
             data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive_offset="on"

             data-textAlign="['right','right','right','left']"
             data-type="text"
             data-voffset="['400','570','570','180']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['800','800','800','800']"
             data-x="['right','right','right','left']"
             data-y="['top','top','top','top']"
             id="slide-903-layer-3"

             style="z-index: 13;
                            white-space: normal;
                            font-weight: 900;
                            color:rgba(0,0,0,0);">
              <span class="slider-text-outline"
                    style="font-family: 'Roboto', sans-serif;text-transform:uppercase;">03</span>
        </div>

        <!-- LAYER NR. 4 [ for see all service botton ] -->
        <div class="tp-caption tp-resizeme"
             data-frames='[
                            {"from":"y:100(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50;opacity:0;","ease":"Power2.easeInOut"}
                            ]'
             data-height="['none','none','none','none']" data-hoffset="['40','40','40','40']"

             data-lineheight="['none','none','none','none']" data-paddingbottom="[0,0,0,0]"
             data-paddingleft="[0,0,0,0]"
             data-paddingright="[0,0,0,0]"
             data-paddingtop="[0,0,0,0]"
             data-responsive_offset="on"

             data-textAlign="['left','left','left','left']"
             data-type="text"
             data-voffset="['570','570','520','460']"
             data-whitespace="['normal','normal','normal','normal']"
             data-width="['300','300','300','300']"
             data-x="['left','left','left','left']"
             data-y="['top','top','top','top']"
             id="slide-903-layer-4"

             style="z-index:13; text-transform:uppercase;">
<!--          <a class="site-button slider-btn-left btn-half" href="javascript:"><span> More About</span><em></em></a>-->
        </div>


      </li>

    </ul>
    <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
  </div>
</div>
<!-- SLIDER END -->
