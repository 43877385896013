<!-- FOOTER START -->
<footer class="site-footer footer-large footer-light	footer-wide">

  <!-- FOOTER START -->
  <div class="footer-top overlay-wrapper">
    <div class="overlay-main"></div>
    <div class="container">

      <div class="row">
        <!-- ABOUT COMPANY -->
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="widget widget_about">
            <!--<h4 class="widget-title">About Company</h4>-->
            <div class="logo-footer clearfix p-b15">
              <a href="../../../index.html"><img src="../../../assets/images/logo-black.svg" alt=""></a>
            </div>
            <p class="max-w400">Today we can tell you, thanks to your passion, hard work creativity, and expertise,
              you
              delivered us the most beautiful house great looks.</p>

            <ul class="social-icons social-tooltips-outer  wt-social-links">
              <li><a href="javascript:void(0);" class="fa fa-facebook"><span
                class="social-tooltips">Facebook</span></a>
              </li>
              <li><a href="javascript:void(0);" class="fa fa-rss"><span class="social-tooltips">Rss</span></a></li>
              <li><a href="javascript:void(0);" class="fa fa-linkedin"><span
                class="social-tooltips">Linkedin</span></a>
              </li>
              <li><a href="javascript:void(0);" class="fa fa-google-plus"><span
                class="social-tooltips">Google Plus</span></a></li>
              <li><a href="javascript:void(0);" class="fa fa-instagram"><span
                class="social-tooltips">Instagram</span></a></li>
            </ul>
          </div>
        </div>


        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="widget widget_services inline-links">
            <h4 class="widget-title">Useful links</h4>
            <ul>
              <li><a href="about-1.html">About</a></li>
              <li><a href="post-gallery.html">Gallery</a></li>
              <li><a href="news-grid.html">Blog</a></li>
              <li><a href="work-masonry.html">Portfolio</a></li>
              <li><a href="project-detail.html">Contact Us</a></li>
              <li><a href="contact-1.html">FAQ </a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="widget widget_services inline-links">
            <h4 class="widget-title">Rooms & Suites</h4>
            <ul>
              <li><a href="javascript:void(0);">Classic</a></li>
              <li><a href="javascript:void(0);">Superior</a></li>
              <li><a href="javascript:void(0);">Delux</a></li>
              <li><a href="javascript:void(0);">Master</a></li>
              <li><a href="javascript:void(0);">luxury</a></li>
              <li><a href="javascript:void(0);">Banquet Halls</a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="widget widget_address_outer">
            <h4 class="widget-title">Contact Us</h4>
            <ul class="widget_address">
              <li><i class="sl-icon-map text-primary"></i> 92 Princess Road, parkvenue,Greater London, NW18JR, United
                Kingdom
              </li>
              <li><i class="sl-icon-envelope-letter text-primary"></i>sharandemo@gmail.com</li>
              <li><i class="sl-icon-phone text-primary"></i>(+0091) 912-3456-073</li>
              <li><i class="sl-icon-printer text-primary"></i>(+0091) 912-3456-084</li>
            </ul>

          </div>
        </div>


      </div>
    </div>
  </div>
  <!-- FOOTER COPYRIGHT -->
  <div class="footer-bottom overlay-wrapper">
    <div class="overlay-main"></div>
    <div class="container">
      <div class="row">
        <div class="wt-footer-bot-center">
          <span class="copyrights-text">© 2021 trunkaug. Designed By Tsubasa Hikichi.</span>
        </div>
      </div>
    </div>
  </div>

</footer>
<!-- FOOTER END -->
