<!-- WELCOME SECTION START -->
<div class="section-full p-tb90 bg-white overflow-hide">

  <div class="container">
    <div class="section-content">

      <div class="row">

        <div class="col-md-6 col-sm-12 text-black">
          <!-- TITLE START -->
          <div class="section-head text-left">
            <h2 class=" m-b5" data-title="About">About Cheers</h2>
            <div class="wt-separator-outer">
              <div class="wt-separator bg-primary"></div>
            </div>
          </div>
          <!-- TITLE END -->
          <h4 class=" m-t0">
            We will be so proud to be our guest.Lorem Ipsum is simply dummy text of the printing.
          </h4>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            typesetting industry's standard dummy text ever since the when.Lorem Ipsum is simply dummy text of the
            printing and typesetting industry.</p>

          <div class="row equal-wrapper">
            <div class="col-md-6 col-sm-6 m-b30">
              <div class="wt-icon-box-wrapper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                <div class="icon-md m-b20">
                  <span class="icon-cell"><i class="flaticon-room-service v-icon"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="wt-tilte">Restaurants</h4>
                  <p>Lorem ipsum dolor sit piscing sed nonmy</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-6 m-b30">
              <div class="wt-icon-box-wrapper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                <div class="icon-md m-b20">
                  <span class="icon-cell"><i class="flaticon-exercise v-icon"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="wt-tilte ">Wellness & Spa</h4>
                  <p>Lorem ipsum dolor sit piscing sed nonmy</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-6  m-b30">
              <div class="wt-icon-box-wrapper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                <div class="icon-md m-b20">
                  <span class="icon-cell"><i class="flaticon-wifi v-icon"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="wt-tilte ">Free Wifi</h4>
                  <p>Lorem ipsum dolor sit piscing sed nonmy</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-6  m-b30">
              <div class="wt-icon-box-wrapper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                <div class="icon-md m-b20">
                  <span class="icon-cell"><i class="flaticon-swimmer v-icon"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="wt-tilte ">Swimming Pool</h4>
                  <p>Lorem ipsum dolor sit piscing sed nonmy</p>
                </div>
              </div>
            </div>
          </div>
<!--          <a class="btn-half site-button button-lg m-b30"-->
<!--             href="javascript:void(0);"><span>More About</span><em></em></a>-->
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="home-about-block-outer bg-repeat bg-white"
               style="background-image:url(assets/images/background/bg-dot.jpg);">
            <div class="home-about-block-inner">
              <div class="home-about-slider owl-carousel owl-btn-vertical-center">

                <div class="item">
                  <div class="home-about-slider-pic">
                    <img alt="" src="assets/images/about/pic1.jpg">
                  </div>
                </div>
                <div class="item">
                  <div class="home-about-slider-pic">
                    <img alt="" src="assets/images/about/pic2.jpg">
                  </div>
                </div>
                <div class="item">
                  <div class="home-about-slider-pic">
                    <img alt="" src="assets/images/about/pic3.jpg">
                  </div>
                </div>
                <div class="item">
                  <div class="home-about-slider-pic">
                    <img alt="" src="assets/images/about/pic4.jpg">
                  </div>
                </div>
                <div class="item">
                  <div class="home-about-slider-pic">
                    <img alt="" src="assets/images/about/pic5.jpg">
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- WELCOME  SECTION END -->
