<!-- OUR SERVICES START -->
<div class="section-full p-tb80">

  <div class="container">
    <!-- TITLE START -->
    <div class="section-head text-left">
      <h2 class="m-b5" data-title="Services">Our Services</h2>
      <div class="wt-separator-outer">
        <div class="wt-separator bg-primary"></div>
      </div>
    </div>
    <!-- TITLE END -->
    <div class="row">
      <div class="col-md-4 col-sm-4">
        <div class="wt-icon-box-wrapper center bdr-1 bdr-gray-light bdr-solid m-b30 p-a20 hover-box-effect  v-icon-effect">
          <div class="icon-md m-b20">
            <span class="icon-cell"><i class="flaticon-wifi v-icon"></i></span>
          </div>
          <div class="icon-content">
            <h4 class="wt-title: ">Free Wi-Fi Available</h4>
            <p>Lorem ipsum dolor sit piscing sed diam nonmy.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="wt-icon-box-wrapper center bdr-1 bdr-gray-light bdr-solid m-b30 p-a20 hover-box-effect  v-icon-effect">
          <div class="icon-md m-b20">
            <span class="icon-cell"><i class="flaticon-room-service v-icon"></i></span>
          </div>
          <div class="icon-content">
            <h4 class="wt-tilte ">Meetings & Special Events</h4>
            <p>Lorem ipsum dolor sit piscing sed diam nonmy.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="wt-icon-box-wrapper center bdr-1 bdr-gray-light bdr-solid m-b30 p-a20 hover-box-effect  v-icon-effect">
          <div class="icon-md m-b20">
            <span class="icon-cell"><i class="flaticon-smartphone v-icon"></i></span>
          </div>
          <div class="icon-content">
            <h4 class="wt-tilte ">Free Cancellation Anytime</h4>
            <p>Lorem ipsum dolor sit piscing sed diam nonmy.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="wt-icon-box-wrapper center bdr-1 bdr-gray-light bdr-solid m-b30 p-a20 hover-box-effect  v-icon-effect">
          <div class="icon-md m-b20">
            <span class="icon-cell"><i class="flaticon-business-cards v-icon"></i></span>
          </div>
          <div class="icon-content">
            <h4 class="wt-tilte ">Best Price Guarantee</h4>
            <p>Lorem ipsum dolor sit piscing sed diam nonmy.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="wt-icon-box-wrapper center bdr-1 bdr-gray-light bdr-solid m-b30 p-a20 hover-box-effect  v-icon-effect">
          <div class="icon-md m-b20">
            <span class="icon-cell"><i class="flaticon-calendar v-icon"></i></span>
          </div>
          <div class="icon-content">
            <h4 class="wt-tilte ">Book Now to Secure Availability</h4>
            <p>Lorem ipsum dolor sit piscing sed diam nonmy.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="wt-icon-box-wrapper center bdr-1 bdr-gray-light bdr-solid m-b30 p-a20 hover-box-effect  v-icon-effect">
          <div class="icon-md m-b20">
            <span class="icon-cell"><i class="flaticon-time-passing v-icon"></i></span>
          </div>
          <div class="icon-content">
            <h4 class="wt-tilte ">Late Check-out on Request</h4>
            <p>Lorem ipsum dolor sit piscing sed diam nonmy.</p>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="text-center">-->
<!--      <a class="btn-half site-button button-lg m-b15 m-t50"-->
<!--         href="javascript:void(0);"><span>View All</span><em></em></a>-->
<!--    </div>-->
  </div>
</div>
<!-- OUR SERVICES END -->
