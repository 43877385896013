<!-- HEADER START -->
<header class="site-header header-style-1">
  <div class="sticky-header main-bar-wrapper">
    <div class="main-bar bg-white p-t5">
      <div class="container">
        <div class="logo-header">
          <div class="logo-header-inner logo-header-one">
            <a href="../../../index.html">
              <img alt="" src="../../../assets/images/logo-black.svg"/>
            </a>
          </div>
        </div>
        <!-- NAV Toggle Button -->
        <button class="navbar-toggle collapsed" data-target=".header-nav" data-toggle="collapse" type="button">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <!-- EXTRA Nav -->
        <div class="extra-nav">
          <div class="extra-cell">
            <a class="contact-slide-show text-white" href="#"><i class="fa fa-envelope-o"></i></a>
          </div>
          <div class="extra-cell">
            <a class=" text-white" href="#search">
              <i class="fa fa-search"></i>
            </a>
          </div>
          <div class="extra-cell">
            <a class="socialicon_show  text-white" href="javascript:">
              <i class="fa fa-share-alt"></i>
            </a>
          </div>
        </div>
        <!-- EXTRA Nav -->

        <!-- Social Nav -->
        <div class="social_hide">
          <div class="side-social-nav">
            <a class="socialicon_close" href="javascript:void(0)">&times;</a>
            <ul class="list-unstyled">
              <li><a class="fa fa-facebook" href="javascript:void(0);"><span
                class="social-tooltip">Facebook</span></a></li>
              <li><a class="fa fa-rss" href="javascript:void(0);"><span class="social-tooltip">Rss</span></a></li>
              <li><a class="fa fa-linkedin" href="javascript:void(0);"><span
                class="social-tooltip">Linkedin</span></a></li>
              <li><a class="fa fa-google-plus" href="javascript:void(0);"><span
                class="social-tooltip">Google Plus</span></a></li>
              <li><a class="fa fa-instagram" href="javascript:void(0);"><span class="social-tooltip">Instagram</span></a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Contact Nav -->
        <div class="contact-slide-hide">
          <div class="contact-nav">
            <a class="contact_close" href="javascript:void(0)">&times;</a>
            <div class="contact-nav-form p-a30">
              <form action="form-handler.php" class="cons-contact-form" method="post">
                <div class="contact-one m-b30">

                  <!-- TITLE START -->
                  <div class="section-head text-center">
                    <h2 class="m-b5" data-title="Form">Get In Touch</h2>
                  </div>
                  <!-- TITLE END -->
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group wt-inputicon-box">
                        <input class="form-control" name="username" placeholder="Name" required type="text">
                        <i class="fs-input-icon sl-icon-user"></i>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group wt-inputicon-box">
                        <input class="form-control" name="email" placeholder="Email" required type="text">
                        <i class="fs-input-icon sl-icon-envelope-letter"></i>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group wt-inputicon-box">
                          <textarea class="form-control " name="message" placeholder="Message" required
                                    rows="3"></textarea>
                        <i class="fs-input-icon sl-icon-envelope"></i>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="text-center">
                        <button class="btn-half site-button button-lg m-b15" name="submit" type="submit"
                                value="Submit">
                          <span>Submit</span><em></em>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="contact-info text-black m-b30">
                <!-- TITLE START -->
                <div class="section-head text-center">
                  <h2 class="m-b5" data-title="Info">Contact Info</h2>
                </div>
                <!-- TITLE END -->
                <ul>
                  <li>
                    <div class="wt-icon-box-wrapper center p-b40">
                      <div class="icon-md m-b20"><i class="sl-icon-phone"></i></div>
                      <div class="icon-content">
                        <h5 class="m-t0 font-weight-500">Phone number</h5>
                        <p>(456) 789 10 12</p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div class="wt-icon-box-wrapper center p-b40">
                      <div class="icon-md m-b20"><i class="sl-icon-envelope-letter"></i></div>
                      <div class="icon-content">
                        <h5 class="m-t0 font-weight-500">Email address</h5>
                        <p>demo@gmail.com</p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div class="wt-icon-box-wrapper center">
                      <div class="icon-md m-b20"><i class="sl-icon-map"></i></div>
                      <div class="icon-content">
                        <h5 class="m-t0 font-weight-500">Address info</h5>
                        <p>55/11 Land Street, Modern New Yourk City, USA</p>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- SITE Search -->
        <div id="search">
          <span class="close"></span>
          <form action="/search" class="radius-xl" id="searchform" method="get" role="search">
            <div class="input-group">
              <input name="q" placeholder="Type to search" type="search" value=""/>
              <span class="input-group-btn"><button class="search-btn" type="button"><i
                class="fa fa-search"></i></button></span>
            </div>
          </form>
        </div>
        <!-- MAIN Vav -->
        <div class="header-nav navbar-collapse collapse">
          <ul class=" nav navbar-nav">
            <li class="active">
              <a href="javascript:">Home</a>
              <ul class="sub-menu">
                <li>
                  <a href="../../../index.html">Home-1</a>
                </li>
                <li>
                  <a href="index-2.html">Home-2</a>
                </li>
                <li>
                  <a href="index-3.html">Home-3</a>
                </li>

              </ul>
            </li>
            <li>
              <a href="javascript:">Post detail</a>
              <ul class="sub-menu">
                <li><a href="post-image.html">Post Image</a></li>
                <li><a href="post-gallery.html">Post Gallery</a></li>
                <li><a href="post-video.html">Post Video</a></li>
                <li><a href="post-right-sidebar.html">Post Right Sidebar</a></li>
              </ul>
            </li>
            <li class="submenu-direction">
              <a href="javascript:">Pages</a>
              <ul class="sub-menu">
                <li>
                  <a href="javascript:">Blog</a>
                  <ul class="sub-menu has-child">
                    <li><a href="news-grid.html">Blog Grid</a></li>
                    <li><a href="news-listing.html">Blog Listing</a></li>
                    <li><a href="news-masonry.html">Blog Masonry</a></li>
                  </ul>
                </li>
                <li>
                  <a href="about-1.html">About us</a>
                </li>
                <li>
                  <a href="contact-1.html">Contact us</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:">Projects</a>
              <ul class="sub-menu">
                <li><a href="work-grid.html">Project Grid</a></li>
                <li><a href="work-masonry.html">Project Masonry</a></li>
                <li><a href="work-carousel.html">Project Carousel</a></li>
                <li><a href="project-detail.html">Project Detail</a></li>
              </ul>
            </li>
            <li class="submenu-direction">
              <a href="javascript:">Shortcodes</a>
              <ul class="sub-menu">
                <li><a href="accordian.html">Accordian</a></li>
                <li><a href="button.html">Button</a></li>
                <li><a href="icon_box.html">Icon box style</a></li>
                <li><a href="list_group.html">List group</a></li>
                <li><a href="modal_popup.html">Modal popup</a></li>
                <li><a href="tabs.html">Tabs</a></li>
                <li><a href="table.html">Table</a></li>
                <li><a href="video.html">Video </a></li>
                <li><a href="icon-font.html">Icon Font </a></li>
              </ul>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</header>
<!-- HEADER END -->
