<!-- ROOMS SLIDER START -->
<div class="section-full p-tb90 bg-gray">
  <div class="container">

    <!-- TITLE START -->
    <div class="section-head text-center">
      <h2 class="m-b5" data-title="Suites">Our Rooms & Suites</h2>
      <div class="wt-separator-outer">
        <div class="wt-separator bg-primary"></div>
      </div>
    </div>
    <!-- TITLE END -->

    <div class="text-center">
      <ul class="btn-filter-wrap2">
        <li class="btn-filter btn-active" data-filter="*">All Rooms</li>
        <li class="btn-filter" data-filter=".col-1">Classic</li>
        <li class="btn-filter" data-filter=".col-2">Superior</li>
        <li class="btn-filter" data-filter=".col-3">Delux</li>
        <li class="btn-filter" data-filter=".col-4">Executive</li>
      </ul>
    </div>
  </div>

  <div class="container-fluid">
    <!-- IMAGE CAROUSEL START -->
    <div class="section-content">
      <div class="owl-carousel owl-carousel-filter2 owl-btn-bottom-center">
        <!-- COLUMNS 1 -->
        <div class="item col-1">
          <div class="room-rent-section-outer">
            <div class="room-rent-section">
              <div class="rooms-pic-section">
                <div class="wt-media">
                  <img alt="" src="assets/images/rooms/pic1.jpg">
                  <div class="overlay-bx-3"></div>
                  <h3 class="m-b0 wt-title">Classic Balcony Room</h3>
                </div>

              </div>
              <div class="room-info-section text-black">
                <span>$299.00/night</span>
                <ul class="clearfix">
                  <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m²</li>
                  <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3</li>
                  <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony</li>
                </ul>
              </div>
            </div>
            <a class="btn-half site-button button-lg" href="room-detail.html"><span>More</span><em></em></a>
          </div>
        </div>

        <!-- COLUMNS 2 -->
        <div class="item col-2">
          <div class="room-rent-section-outer">
            <div class="room-rent-section">
              <div class="rooms-pic-section">
                <div class="wt-media">
                  <img alt="" src="assets/images/rooms/pic2.jpg">
                  <div class="overlay-bx-3"></div>
                  <h3 class="m-b0 wt-title">Superior Double Room</h3>
                </div>

              </div>
              <div class="room-info-section text-black">
                <span>$399.00/night</span>
                <ul class="clearfix">
                  <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m²</li>
                  <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3</li>
                  <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony</li>
                </ul>
              </div>
            </div>
            <a class="btn-half site-button button-lg" href="room-detail.html"><span>More</span><em></em></a>
          </div>
        </div>

        <!-- COLUMNS 3 -->
        <div class="item col-3">
          <div class="room-rent-section-outer">
            <div class="room-rent-section">
              <div class="rooms-pic-section">
                <div class="wt-media">
                  <img alt="" src="assets/images/rooms/pic3.jpg">
                  <div class="overlay-bx-3"></div>
                  <h3 class="m-b0 wt-title">Balcony Double Room</h3>
                </div>

              </div>
              <div class="room-info-section text-black">
                <span>$299.00/night</span>
                <ul class="clearfix">
                  <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m²</li>
                  <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3</li>
                  <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony</li>
                </ul>
              </div>
            </div>
            <a class="btn-half site-button button-lg" href="room-detail.html"><span>More</span><em></em></a>
          </div>
        </div>

        <!-- COLUMNS 4 -->
        <div class="item col-4">
          <div class="room-rent-section-outer">
            <div class="room-rent-section">
              <div class="rooms-pic-section">
                <div class="wt-media">
                  <img alt="" src="assets/images/rooms/pic4.jpg">
                  <div class="overlay-bx-3"></div>
                  <h3 class="m-b0 wt-title">Delux Double Room</h3>
                </div>

              </div>
              <div class="room-info-section text-black">
                <span>$299.00/night</span>
                <ul class="clearfix">
                  <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m²</li>
                  <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3</li>
                  <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony</li>
                </ul>
              </div>
            </div>
            <a class="btn-half site-button button-lg" href="room-detail.html"><span>More</span><em></em></a>
          </div>
        </div>

        <!-- COLUMNS 5 -->
        <div class="item col-3">
          <div class="room-rent-section-outer">
            <div class="room-rent-section">
              <div class="rooms-pic-section">
                <div class="wt-media">
                  <img alt="" src="assets/images/rooms/pic5.jpg">
                  <div class="overlay-bx-3"></div>
                  <h3 class="m-b0 wt-title">Classic Balcony Room</h3>
                </div>

              </div>
              <div class="room-info-section text-black">
                <span>$299.00/night</span>
                <ul class="clearfix">
                  <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m²</li>
                  <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3</li>
                  <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony</li>
                </ul>
              </div>
            </div>
            <a class="btn-half site-button button-lg" href="room-detail.html"><span>More</span><em></em></a>
          </div>
        </div>

        <!-- COLUMNS 6 -->
        <div class="item col-2">
          <div class="room-rent-section-outer">
            <div class="room-rent-section">
              <div class="rooms-pic-section">
                <div class="wt-media">
                  <img alt="" src="assets/images/rooms/pic6.jpg">
                  <div class="overlay-bx-3"></div>
                  <h3 class="m-b0 wt-title">Superior Double Room</h3>
                </div>

              </div>
              <div class="room-info-section text-black">
                <span>$299.00/night</span>
                <ul class="clearfix">
                  <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m²</li>
                  <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3</li>
                  <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony</li>
                </ul>
              </div>
            </div>
            <a class="btn-half site-button button-lg" href="room-detail.html"><span>More</span><em></em></a>
          </div>
        </div>

        <!-- COLUMNS 7 -->
        <div class="item col-1">
          <div class="room-rent-section-outer">
            <div class="room-rent-section">
              <div class="rooms-pic-section">
                <div class="wt-media">
                  <img alt="" src="assets/images/rooms/pic7.jpg">
                  <div class="overlay-bx-3"></div>
                  <h3 class="m-b0 wt-title">Delux Double Room</h3>
                </div>

              </div>
              <div class="room-info-section text-black">
                <span>$299.00/night</span>
                <ul class="clearfix">
                  <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m²</li>
                  <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3</li>
                  <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony</li>
                </ul>
              </div>
            </div>
            <a class="btn-half site-button button-lg" href="room-detail.html"><span>More</span><em></em></a>
          </div>
        </div>

        <!-- COLUMNS 8 -->


      </div>
    </div>
  </div>

</div>
<!-- ROOMS  SLIDER END -->
