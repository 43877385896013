import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonToTopComponent} from './button-to-top/button-to-top.component';
import {FullScreenSliderComponent} from './full-screen-slider/full-screen-slider.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {RoomsSliderComponent} from './rooms-slider/rooms-slider.component';
import {FeaturesComponent} from './features/features.component';
import {ServicesComponent} from './services/services.component';
import {TeamMembersComponent} from './team-members/team-members.component';
import {MapComponent} from './map/map.component';
import {ContactComponent} from './contact/contact.component';
import {RouterModule} from '@angular/router';
import {AgmCoreModule} from '@agm/core';

@NgModule({
  declarations: [
    AboutUsComponent,
    ButtonToTopComponent,
    ContactComponent,
    FeaturesComponent,
    FullScreenSliderComponent,
    MapComponent,
    RoomsSliderComponent,
    ServicesComponent,
    TeamMembersComponent,
  ],
  imports     : [
    CommonModule,
    RouterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBOSUHJ9IDDk3itghaygIXDYICzydzc2nk'
    }),
  ],
  exports     : [
    CommonModule,
    RouterModule,
    AboutUsComponent,
    ButtonToTopComponent,
    ContactComponent,
    FeaturesComponent,
    FullScreenSliderComponent,
    MapComponent,
    RoomsSliderComponent,
    ServicesComponent,
    TeamMembersComponent,
  ]
})
export class UiModule {
}
