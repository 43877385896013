import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {rootRoutes} from './app-routing.module';
import {CoreModule} from './core/core.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports     : [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(rootRoutes, {
      enableTracing     : false,
      preloadingStrategy: PreloadAllModules,
      initialNavigation : 'enabled',
      useHash           : false
    }),
    CoreModule
  ],
  providers   : [],
  bootstrap   : [AppComponent]
})
export class AppModule {
}
